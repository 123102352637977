import { InjectionToken } from "@angular/core";
import { LocaleService } from "@smallstack/i18n-components";

// vvvvvv This list is deprecated. Features and Types are slowly moving to libs\core-common\src\lib\constants\data-types.ts
// features
export const F_I18N = "internationalization";
export const F_PIM = "products";
export const F_CUSTOM_BACKOFFICES = "custombackoffices";
export const F_DEVICES = "devices";
export const F_CONFIGURATION = "configuration";
export const F_CUSTOM = "custom";
export const F_EMAIL = "email";
export const F_CMS = "cms";
export const F_USERS = "users";
export const F_FILES = "files";
export const F_IMPEX = "impex";
export const F_WORKFLOW = "workflow";
export const F_OPTIN = "optin";
export const F_APPLICATIONS = "applications";
// custom types
export const T_APPLICATION = "application";
// ^^^^^^^^^^^^

// INJECTs
export const PROJECT_LOCALE_SERVICE = new InjectionToken<LocaleService>("localeService");

// CONFIG KEYS
export const RESELLER_CONFIG_APPLICATIONS_URL = "applications.url";

export const ANALYTICS_EVENT = {
  openBackoffice: "openBackoffice",
  changedPassword: "changedPassword",
  changedIconTheme: "changedIconTheme",
  addedMicrosoftLogin: "addedMicrosoftLogin",
  removedMicrosoftLogin: "removedMicrosoftLogin",
  createdAccessToken: "createdAccessToken",
  removedAccessToken: "removedAccessToken",
  enabledAdvancedMode: "enabledAdvancedMode",
  disabledAdvancedMode: "disabledAdvancedMode",
  enabledPreviewMode: "enabledPreviewMode",
  disabledPreviewMode: "disabledPreviewMode"
};
export const ANALYTICS_EVENT_INFO_KEY = {
  backofficeName: "backofficeName",
  personalBackofficeName: "personalBackofficeName",
  projectName: "projectName",
  companyName: "companyName",
  customBackofficeName: "customBackofficeName"
};
