import { Injectable } from "@angular/core";
import { ConfigurationDtoScopeEnum } from "@smallstack/axios-api-client";
import { ConfigurationStore } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { TodoConfiguration, TodoConfigurationService, TODO_CONFIGURATION_KEY } from "@smallstack/todo-shared";
import { synchronized } from "synchronized-ts";

@Injectable({ providedIn: "root" })
export class BackofficeTodoConfigurationService extends TodoConfigurationService {
  constructor(
    private configurationStore: ConfigurationStore,
    private notificationService: NotificationService
  ) {
    super();
  }

  @synchronized
  public override async getConfiguration(): Promise<TodoConfiguration> {
    const storedConfig = await this.configurationStore.getConfigurationByKey(TODO_CONFIGURATION_KEY);
    if (storedConfig) {
      try {
        return JSON.parse(storedConfig.value);
      } catch (e) {
        this.notificationService.showStandardErrorPopup(
          e,
          "Fehler während dem Einlesen der Aufgaben Konfiguration. Standardwerte werden verwendet."
        );
        return super.getConfiguration();
      }
    } else return super.getConfiguration();
  }

  public async saveConfiguration(config: TodoConfiguration): Promise<void> {
    await this.notificationService.handlePromise(
      this.configurationStore.upsertConfiguration(
        TODO_CONFIGURATION_KEY,
        JSON.stringify(config),
        ConfigurationDtoScopeEnum.Public
      )
    );
  }

  public getDefaultConfiguration(): Promise<TodoConfiguration> {
    return super.getConfiguration();
  }
}
