<div mat-dialog-title class="flex flex-row justify-between items-center">
  <div class="flex flex-row space-x-2 items-center"
    ><smallstack-icon size="36" [name]="T_CALENDAR_ENTRIES | typeIcon | async" />

    <span>Termine mit {{ dialogData.contact.firstName }} {{ dialogData.contact.lastName }}</span></div
  >
  <mat-checkbox (change)="togglePastEntries($event)">Vergangene Termine anzeigen</mat-checkbox>
</div>
<div mat-dialog-content>
  @if (calendarEntriesSearchStore) {
    <smallstack-store-container [store]="calendarEntriesSearchStore">
      @if (calendarEntriesSearchStore.value$ | async; as entries) {
        <div class="smallstack-grid">
          @if (entries.length !== 0) {
            @for (entry of entries; track entry) {
              <div class="smallstack-grid-card">
                <div class="content center-center">
                  <smallstack-icon size="48" [name]="'calendarEntries' | typeIcon | async" />
                  <div style="text-align: center; padding-bottom: 10px">
                    <h3><smallstack-i18n [data]="entry.title" /></h3>
                  </div>
                  <table style="text-align: left">
                    <tr>
                      <td>Beginn</td>
                      <td> <smallstack-date format="medium" [timestamp]="entry.start" /> </td>
                    </tr>
                    @if (entry.end) {
                      <tr>
                        <td>Ende</td>
                        <td> <smallstack-date format="medium" [timestamp]="entry.end" /> </td>
                      </tr>
                    }
                    @if (entry.content) {
                      <tr>
                        <td>Inhalt</td>
                        <td> <smallstack-i18n [data]="entry.content" /> </td>
                      </tr>
                    }
                  </table>
                </div>
                <div class="actions">
                  <button class="btn btn-outline btn-primary" [loadingFn]="editNewCalendarEntry(entry)">
                    <smallstack-icon alias="edit" />
                  </button>
                </div>
              </div>
            }
          } @else {
            <div class="smallstack-grid-card">
              <div class="content center-center">
                <smallstack-icon size="48" style="filter: grayscale()" [name]="'calendarEntries' | typeIcon | async" />
                <div style="text-align: center; padding-bottom: 10px">
                  <div class="text-muted">Keine Termine gefunden</div>
                </div>
              </div>
            </div>
          }
          <div class="smallstack-grid-card cursor-pointer" [loadingFn]="createNewCalendarEntry()">
            <div class="content center-center">
              <smallstack-icon size="48" name="add" />
              <div>
                <b>Termin erstellen</b>
              </div>
              <div class="text-muted">
                Erstellte Termine werden automatisch mit {{ dialogData.contact.firstName }}
                {{ dialogData.contact.lastName }} verknüpft.</div
              >
            </div>
          </div>
        </div>
      }
    </smallstack-store-container>
  }
</div>
