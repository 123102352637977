import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const RECEIVED_INVITATION_WIDGET_NAME = "ReceivedInvitation";

export const ReceivedInvitationWidget: WidgetRegistryEntry = {
  name: RECEIVED_INVITATION_WIDGET_NAME,
  configuration: {
    dataSchema: {
      type: "object",
      properties: {
        invitation: { type: "object" }
      }
    }
  },
  component: () => import("./received-invitation-widget.component").then((m) => m.ReceivedInvitationWidgetComponent)
};
