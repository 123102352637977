import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactDto, WidgetDto } from "@smallstack/axios-api-client";
import { BackofficeIcons } from "@smallstack/backoffice-shared";
import { ContactDetailDialogComponent } from "@smallstack/cms-components";
import { DetailDialogData, RouterUtilService } from "@smallstack/common-components";
import { ContactsStore } from "@smallstack/crm-components";
import { NotificationService } from "@smallstack/i18n-components";
import { TodoListDialogComponent, TodoListDialogData } from "@smallstack/todo-components";
import { TYPE_CONTACTS, T_CONTACT, T_TODOS, UserType } from "@smallstack/typesystem";
import { TypeDialogService, Widget } from "@smallstack/widget-core";
import { ContactCalendarEntriesDialogComponent } from "../contact-calendar-entries-dialog/contact-calendar-entries-dialog.component";

@Widget({
  name: "ContactList",
  icon: UserType.icon,
  templateName: "Kontaktliste",
  templateDescription: "Zeigt alle Kontakte in einer Liste an"
})
@Component({
  selector: "smallstack-contact-list",
  templateUrl: "./contact-list.component.html",
  styleUrls: ["./contact-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListComponent {
  public readonly BACKOFFICE_ICONS = BackofficeIcons;
  public readonly T_TODOS = T_TODOS;
  public readonly COLUMNS = [
    "avatar",
    "salutation",
    "title",
    "firstName",
    "lastName",
    "company",
    "companyPhone",
    "email",
    "phone",
    "mobile",
    "actions"
  ];
  protected searchContainer: WidgetDto = {
    name: "VerticalContainer",
    data: {
      invertedDirection: false,
      wrap: false,
      alignItems: "stretch",
      justifyContent: "flex-start",
      width: "100%",
      children: [
        {
          name: "IconTabs",
          data: {
            tabs: [
              {
                icon: "search",
                component: {
                  name: "TextSearch",
                  data: {
                    type: "contacts",
                    globalStoreSearch: true
                  },
                  id: "6a850d41-bfe7-4b36-a562-f3ff3107d6f4",
                  styles: ""
                }
              },
              {
                icon: "filter",
                component: {
                  name: "FilterBar",
                  data: {
                    type: "contacts",
                    globalStoreSearch: true
                  },
                  id: "49a4589a-cb4a-46df-8c23-1260d9d171c7",
                  styles: ""
                }
              }
            ]
          },
          id: "b0edc122-e233-4753-a279-e8a9fa908564",
          styles: ""
        }
      ]
    }
  };

  constructor(
    public contactStore: ContactsStore,
    private typeDialogService: TypeDialogService,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private routerUtilService: RouterUtilService,
    activatedRoute: ActivatedRoute
  ) {
    void activatedRoute.queryParams.subscribe(async (params) => {
      if (typeof params.openDetailsId === "string" && this.matDialog.openDialogs.length === 0) {
        const contact = await this.contactStore.get(params.openDetailsId);
        if (contact) {
          void this.onOpenContactDetails(contact);
        } else this.notificationService.notification.error("Kein Kontakt gefunden für ID " + params.openDetailsId);
      }
      if (typeof params.openTodoId === "string") {
        const contact = await this.contactStore.get(params.openTodoId);
        if (contact) {
          void this.openTodos(contact);
        }
      }
    });
  }

  public async onOpenContactDetails(contact: ContactDto): Promise<void> {
    void this.matDialog
      .open(ContactDetailDialogComponent, {
        data: { model: contact } as DetailDialogData,
        autoFocus: false,
        maxHeight: "80vh"
      })
      .beforeClosed()
      .subscribe(() => {
        return this.routerUtilService.removeQueryParameter("openDetailsId");
      });
  }

  public async onOpenContactEditor(contact?: ContactDto): Promise<void> {
    const model = await this.typeDialogService.openEditor({ typePath: TYPE_CONTACTS }, { model: contact });
    if (model?.id) await this.contactStore.reloadOne(model.id);
    else await this.contactStore.load();
    this.cdr.markForCheck();
  }

  public onDeleteContact(contactId: string) {
    return async (): Promise<void> => {
      if (
        await this.notificationService.popup.confirmation(
          "@@components.crm.contacts.deletion.title",
          "@@components.crm.contacts.deletion.message",
          [
            { label: "@@actions.no", value: false },
            { label: "@@actions.yes", color: "warn", value: true }
          ]
        )
      )
        await this.contactStore.delete(contactId);
      this.cdr.markForCheck();
    };
  }

  public openCalendarEntries(contact: ContactDto): void {
    this.matDialog.open(ContactCalendarEntriesDialogComponent, {
      data: { contact },
      minWidth: "60vw",
      autoFocus: false
    });
  }

  public openTodos(contact: ContactDto): void {
    void this.matDialog
      .open(TodoListDialogComponent, {
        data: {
          link: { id: contact.id, type: T_CONTACT },
          headerText: `Aufgaben verknüpft mit ${contact.firstName} ${contact.lastName}`,
          explanation: `Hinweis: Hier werden nur Aufgaben angezeigt, die Ihnen zugewiesen und mit ${contact.firstName} ${contact.lastName} verknüpft sind.`
        } as TodoListDialogData,
        minHeight: "50vh",
        maxHeight: "80vh",
        autoFocus: false
      })
      .afterClosed()
      .subscribe(() => {
        return this.router.navigate([], { queryParams: { openTodoId: null } });
      });
  }
}
